import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard5 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle5CategoryCard5";
import SingleProductPage5 from "../pages/SingleProductPage5";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page5 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard5 />
        <SingleProductPage5 />
        
        <Footer />
    </div>
    );
  };

export default Page5;
