import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard8 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle8CategoryCard8";
import SingleProductPage8 from "../pages/SingleProductPage8";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page8 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard8 />
        <SingleProductPage8 />
        
        <Footer />
    </div>
    );
  };

export default Page8;
