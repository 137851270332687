import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";
import PetrolAll from "./AllProductPages/PetrolAll";

const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://guardian.ng/wp-content/uploads/2018/07/Petrol-tankers-waiting-to-load-at-NNPC-depot.jpg"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            The artificial fuel which is made of chemical conversions or the fuel which we get by processing the primary fuel is called secondary fuel; example inclues: 
            <br />* Premium Motor Spirit (PMS) - Petrol
            <br />* Automotive Gas Oil (AGO) - Diesel
            <br />* Dual Purpose Kerosene (DPK) - Kerosene
            <br />* Low Pour Fuel Oil (LPFO) - Black Oil 
            <br /> <br />
            Fuels are dense repositories of energy that are consumed to provide energy services such as heating, transportation and electrical generation. Even though most fuels ultimately get their energy from the sun they are usually considered to be a primary energy source.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const PetrolAllPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <PetrolAll />
        
        <Footer />
    </div>
    );
  };

export default PetrolAllPage;
