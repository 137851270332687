import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import PhaseSeparationP from "./AllProductPages/PhaseSeparationP";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";


const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://media.licdn.com/dms/image/v2/D5612AQH9tYCvJKihPA/article-inline_image-shrink_1500_2232/article-inline_image-shrink_1500_2232/0/1708818665791?e=1732147200&v=beta&t=_jDEGxweyPbvyK4cvOOTj--xVhtUAImvXcdpg5ER1YI"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            Phase separation is the creation of two distinct phases from a single homogeneous mixture. The most common type of phase separation is between two immiscible liquids, such as oil and water. This type of phase separation is known as liquid-liquid equilibrium. <br /> <br />
            This is done in so called high pressure separators, and mid or low pressure separators. This process is a continuous process, where at the front of the separator the liquid/gas is constant added. The gas is released at the top and the oil-water mixture is separating by using gravity and chemicals.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const PhaseSepPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <PhaseSeparationP />
        
        <Footer />
    </div>
    );
  };

export default PhaseSepPage;
