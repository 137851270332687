import React from "react";
import { Link } from "react-router-dom";


const DegreaserPDetailCard = ({ data }) => {  
    
  return (
    <>
      <div className="w-full h-[350px] bg-[#F6F6F5] rounded-lg shadow-sm py-1 px-2 relative">
       <Link to={`/product/degreaser-and-disinfectant/${data._id}`}>
          <img
            src={`${data.imageUrl}`}
            alt=""
            className="w-full h-[180px] object-contain cursor-pointer"
          />
        </Link>
        <h4 className="pb-3 font-[500] text-[#000]">
            {data.title}
        </h4>
        <p>{data.description.length > 100 ? data.description.slice(0, 100) + "..." : data.description}</p>

        <Link to="mailto:info@ohkoil.com" target="_blank" className="inline-block">
            <div className='mt-2'>
                 <h5 className="text-[blue] font-[Poppins] !text-[14px]">
                 Get a Quote
                 </h5>
            </div>
        </Link>  
        
      </div>
    </>
  );
};

export default DegreaserPDetailCard;

