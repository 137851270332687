import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Degreaser from "./AllProductPages/Degreaser";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";

const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://www.nchasia.com/en-hk/wp-content/resources/sites/18/2023/04/water-based-tile.jpg"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            A degreaser also called precision cleaner, maintenance cleaner, and (specific for automotive repair) carb cleaner or brake cleaner is a solvent or water-based cleaner designed to remove grease, oils, cutting fluids, corrosion inhibitors, handling soils, finger prints, and other contamination. <br /> <br />
            Degreasers are largely affordable and can be bought in bulk to clean different corridors of a manufacturing unit. Time- Saving - Since degreasers are meant to clean shells very fast, this will help you consume lower critical resources like manpower, equipment, water, and other assets.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const DegreaserPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <Degreaser />
        
        <Footer />
    </div>
    );
  };

export default DegreaserPage;
