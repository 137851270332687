

// navigation Data
export const navItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Products",
      url: "/products",
    },
    {
      title: "Contact",
      url: "/contact",
    },
    
  ];


    
  // General categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Drilling and Completion Fluid Additives",
      },
    {
      id: 2,
      title: "Phase Separation Products",
      },
    {
      id: 3,
      title: "Rigs",
      },
    {
      id: 4,
      title: "Flow Assurance Products",
      },
    {
      id: 5,
      title: "Chemical Degreasers/Disinfectant & Multi-purpose Detergent",
      },
    {
      id: 5,
      title: "Water Treatment Chemicals",
      },
    {
      id: 6,
      title: "Hydro Testing/Refinery and Process Chemicals",
      },
    {
      id: 7,
      title: "Secondary Fuels",
      },
    
  ];
   
  
  export const footerNLinks = [
    {
      name: "About us",
      link: "/"
    },
    {
      name: "Contact us",
      link: "/contact"
    },
    
  ];


  export const footerNLinks2 = [
    {
      name: "Driling and Completion Fluid Additives",
      link: "/driling-and-completion-fluid-additives"
    },
    {
      name: "Phase Separation",
      link: "/phase-separation"
    },
    {
      name: "Rigs",
      link: "/rigs"
    },
    {
      name: "Flow Assurance",
      link: "/flow-assurance"
    },
    {
      name: "Chemical Degreasers",
      link: "/degreaser-and-disinfectant"
    },
    {
      name: "Water Treatment Chemicals",
      link: "/water-treatment-chemicals"
    },
    {
      name: "Hydro Testing and Process Chemicals",
      link: "/hydro-testing-and-process-chemicals"
    },
    {
      name: "Secondary Fuels",
      link: "/secondary-fuels"
    },
  ];




  export const drillingCFDatab = [
    {
      _id : "1",
      name: "Calcium Bromide",
      description: "A component of high-density for clear drilling, completion work over, and packing fluids to control wellbore pressures",
      imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmWP1yp88_Y1S-xYpyIWjuFKGK7mUrzVoqMg&s",
    },
    {
      _id : "2",
      name: "Barite & Bentonite",
      description: "Bentonite is a key component in drilling mud and a pivotal ingredient in oil and gas exploration",
      imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmWP1yp88_Y1S-xYpyIWjuFKGK7mUrzVoqMg&s",
    },
    
  ];


  export const drillingCFData = [
    {
      _id : "1",
      title: "Calcium Bromide",
      description: "A component of high-density for clear drilling, completion work over, and packing fluids to control wellbore pressures",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmWP1yp88_Y1S-xYpyIWjuFKGK7mUrzVoqMg&s",
    },
    {
      _id : "2",
      title: "Barite & Bentonite",
      description: "Bentonite is a key component in drilling mud and a pivotal ingredient in oil and gas exploration",
      imageUrl: "https://asiagilsonite.com/wp-content/uploads/2022/05/Asia-Gilsonite-Drilling-scaled-min-min.jpg",
    },
    {
      _id : "3",
      title: "Calcium Chloride",
      description: "Used to create solids free brine workover and completion fluids. It can be used to prepare weighted drilling fluids where formation pressures require densities ranging from 1.02 - 1.40sg (8.5 - 11.7lb / bbl).",
      imageUrl: "https://www.oceanviewchem.com/data/thumb/res/en/20180314/6c152b721e414583.jpg_20180314144806_500x360.jpg",
    },
    {
      _id : "4",
      title: "Potassium Chloride",
      description: "Used for the preparation of water-based drilling and stimulation fluids in which it acts as a shale inhibitor, ensuring stability in shale and clay environments.",
      imageUrl: "https://www.researchgate.net/publication/379260371/figure/fig4/AS:11431281231251128@1711390725617/Sacks-of-industrial-potassium-chloride-used-in-large-scale-agriculture-PHOTO-CREDIT-BY.ppm",
    },
    {
      _id : "5",
      title: "Primary Emulsifier",
      description: "Primary Emulsifier is essentially Polyaminated Fatty acid and is used to emulsify Water into Oil in Oil /Diesel based drilling fluids.",
      imageUrl: "https://www.oceanviewchem.com/data/thumb/res/en/20180529/3b81d6ed4573bc36.jpg_20180529150429_500x360.jpg",
    },
    {
      _id : "6",
      title: "Sodium Chloride",
      description: "Sodium chloride serves as an effective inhibitor in drilling mud & fluid applications, improve performance & efficiency in drilling operation.",
      imageUrl: "https://atdmdrilling.com/wp-content/uploads/2022/08/Sodium-Chloride.jpg",
    },
    {
      _id : "7",
      title: "Oil Mud Thinner",
      description: "A heavy, viscous fluid mixture that is used in oil and gas drilling operations to carry rock cuttings to the surface and also to lubricate and cool the drill bit.",
      imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/DiamondRig-SuperFoam.jpg/640px-DiamondRig-SuperFoam.jpg",
    },
    {
      _id : "8",
      title: "Oxygen Scavenger",
      description: "Oxygen scavengers capture the dissolved oxygen in a harmless chemical reaction that renders the oxygen unavailable for corrosive reactions.",
      imageUrl: "https://www.hawkinsinc.com/wp-content/uploads/2021/02/oil-8b_image.jpg",
    },
    {
      _id : "9",
      title: "OXD Polymer",
      description: "Polymers and additives enable drilling contractors to match drilling fluids to soil conditions to maximize performance, efficiency and success.",
      imageUrl: "https://assets.spe.org/dims4/default/5c1b84d/2147483647/strip/true/crop/768x496+0+0/resize/800x517!/quality/90/?url=http%3A%2F%2Fspe-brightspot.s3.us-east-2.amazonaws.com%2F19%2Fff%2F95e61b1925e9871f38810e812c54%2Fjpt-2019-11-190967f1-hero.jpg",
    },
    {
      _id : "10",
      title: "Secondary Emulsifier",
      description: "It is multipurpose product which may be used in a wide variety of oil mud system. · It improves emulsion stability and functions as a secondary wetting agent.",
      imageUrl: "https://www.rheologicaladditive.com/wp-content/uploads/2020/12/primary-and-secondary-emulsifiers.jpg",
    },
    {
      _id : "11",
      title: "Non-Ionic Surfactant",
      description: "Non-ionic surfactant is an excellent emulsifier and is used at any point in the drilling operation to improve emulsification and reduce torque and drag.",
      imageUrl: "https://img.ogj.com/files/base/ebm/ogj/image/2023/05/230526_AkerBP.6470e2074087f.png?auto=format,compress&fit=max&q=45&w=640&width=640",
    },
  ];


  export const phaseSP = [
    {
      _id : "1",
      title: "Demulsifiers",
      description: "Demulsifiers are used during production and refining to remove salts and formation water from oil.",
      imageUrl: "https://5.imimg.com/data5/SELLER/Default/2022/11/IY/YQ/EG/971284/oil-soluble-demulsifier-500x500.jpg",
    },
    {
      _id : "2",
      title: "Water Clarifiers",
      description: "Used to remove small particles that are suspended in the water, such as algae, bacteria, and dirt, which can cause the water to appear cloudy or hazy.",
      imageUrl: "https://www.monroeenvironmental.com/wp-content/uploads/circular-clarifier-at-steel-mill_feature.jpg",
    },
  ];


  export const rigsLink = [
    {
      _id : "1",
      title: "Oil Field Land Rigs",
      description: "Land rigs are portable onshore drilling rigs used to explore, appraise and develop hydrocarbon assets",
      imageUrl: "https://www.shutterstock.com/image-photo/oil-drilling-derrick-pumps-ground-600nw-2483754243.jpg",
    },
    {
      _id : "2",
      title: "Skid Mounted Drilling Rigs",
      description: "Special Heavy Duty SKID Mounted Drilling Rig for drilling Angular holes from 20 degree horizontal to Vertical to a depth of 30mtrs specially used for drilling Grout holes for Strengthening of Foundation.",
      imageUrl: "https://image.made-in-china.com/229f0j00wECYOSbrrDuJ/RCH-3AFullHydraulicUndergroundCoreMineDrillingRigMachine-mp4.webp",
    },
    {
      _id : "3",
      title: "Jack Up Offshore Rigs",
      description: "Jacked-up rigs are floating drilling rigs commonly used when drilling oil from deep-sea reservoirs.",
      imageUrl: "https://salecharter.net/wp-content/uploads/2017/09/Jack-Up-Drilling-Rig-for-Sale.jpg",
    },
    {
      _id : "4",
      title: "Truck Mounted Drilling Rigs",
      description: "Mainly applicable to all kinds of drilling process of wells, monitor boreholes, geothermal boreholes, and grouting holes of cofferdam, dam and reinforced base, as well as drilling of open pit mining, anchor reinforcement project and defense project",
      imageUrl: "https://www.jkdrilling.com/data/watermark/20180622/5b2ca55567191.jpg",
    },
  ];


  export const flowAssuranceData = [
    {
      _id : "1",
      title: "Corrosion Inhibitors",
      description: "Corrosion inhibitors are chemicals that can be added to water to slow down or prevent corrosion",
      imageUrl: "https://www.assetintegrityengineering.com/wp-content/uploads/2023/07/chemical-management-service-provider.jpg",
    },
    {
      _id : "2",
      title: "Scale Inhibitors",
      description: "Scale inhibitors (SIs) are a class of specialty chemicals that are used to slow or prevent scaling in water systems.",
      imageUrl: "https://diamondprima.com/wp-content/uploads/2018/04/shutterstock_369057299-SCALE-INHIBITOR-BOILER.jpg",
    },
    {
      _id : "3",
      title: "Demulsifiers",
      description: "The description will go here and every other wrtie up and things we do to get you upward",
      imageUrl: "https://www.rimpro-india.com/m/images/blog-img.jpg",
    },
    {
      _id : "4",
      title: "Anti-Foams",
      description: "A chemical additive that reduces and hinders the formation of foam in industrial process liquids",
      imageUrl: "https://www.brb-international.com/clients/asset_854EB699-0F42-4F14-AFF3-345C27039448/contentms/img/Silicone/product/antifoam.jpg",
    },
    {
      _id : "5",
      title: "Biocides",
      description: "Used to preserve goods, stop pests like insects or rodents and control viruses, bacteria and fungi through a chemical or biological action.",
      imageUrl: "https://www.rivm.nl/sites/default/files/styles/content_full/public/2023-09/iStock-1327940776.jpg?h=62bc044e&itok=9FwCg9YI",
    },
    {
      _id : "6",
      title: "Paraffin Dispersants & Inhibitors",
      description: "Paraffin dispersants—surface-active agents that prevent paraffin deposition in oilfield systems by creating repulsion between paraffin particles as well as between paraffin particles and other (e.g., pipe) surfaces.",
      imageUrl: "https://fqechemicals.com/wp-content/uploads/sagd-featured-image.jpg",
    },
    {
      _id : "7",
      title: "Pour Point Dispersants",
      description: "Pour-Point Dispersants are used to enable mineral oils to function efficiently at low temperatures",
      imageUrl: "https://www.bcl.co.za/wp-content/uploads/2018/07/044-1824-x-1368.jpg",
    },
  ];


  export const chemicalDegreasers = [
    {
      _id : "1",
      title: "OHK Degreaser",
      description: "A powerful solvent based degreaser that actually dissolves grease and oil.",
      imageUrl: "https://i.ibb.co/QMGYnWp/rw3.jpg",
    },
    {
      _id : "2",
      title: "OHK Rig Wash",
      description: "A mild liquid alkaline degreaser designed to provide the most basc surface cleaning.",
      imageUrl: "https://i.ibb.co/Xytw0Vy/rw4.jpg",
    },
    {
      _id : "3",
      title: "OHK Sodium Hypochlorite Solution",
      description: "This is a compound that can be effectively used for water purification. Also used for surface purification, bleaching, odor removal and water disinfectant.",
      imageUrl: "https://i.ibb.co/VMCt9Zk/rw1.jpg",
    },
    {
      _id : "4",
      title: "OHK Teepol",
      description: "Teepol is a multi-purpose detergent. It cleans all surface to a bright finish and completely removes all grease.",
      imageUrl: "https://i.ibb.co/mB6854s/rw2.jpg",
    },
  ];


  export const disAndMultiPD = [
    {
      _id : "1",
      title: "OHK Sodium Hypochlorite Solution",
      description: "This is a compound that can be effectively used for water purification. Also used for surface purification, bleaching, odor removal and water disinfectant.",
      imageUrl: "https://i.ibb.co/VMCt9Zk/rw1.jpg",
    },
    {
      _id : "2",
      title: "OHK Teepol",
      description: "Teepol is a multi-purpose detergent. It cleans all surface to a bright finish and completely removes all grease.",
      imageUrl: "https://i.ibb.co/mB6854s/rw2.jpg",
    },
  ];


  export const waterTChemical = [
    {
      _id : "1",
      title: "Reversed Demulsifiers",
      description: "Reverse Demulsifier is used for breaking oil in water emulsions. It is a powerful flocculant to clarify oily water from produced water.",
      imageUrl: "https://media.licdn.com/dms/image/v2/D4D12AQH2xucATDKwZQ/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1721015489488?e=2147483647&v=beta&t=_oTy4taGjdZ2rn1DgEW1SufwehOaggRG7BCjL3HImTQ",
    },
    {
      _id : "2",
      title: "Flocculants",
      description: "Flocculants help to remove suspended solids from wastewater by aggregating contaminants into flakes or “flocs” that float to the surface of the water or settle at the bottom.",
      imageUrl: "https://acornwater.ie/wp-content/uploads/2019/10/carbon-thumb.jpg",
    },
    {
      _id : "3",
      title: "Bactericides",
      description: "A bactericide or bacteriocide, sometimes abbreviated Bcidal, is a substance which kills bacteria. Bactericides are disinfectants, antiseptics, or antibiotics.",
      imageUrl: "https://d1g9yur4m4naub.cloudfront.net/image-handler/picture/2016/3/Bacteriophage_viruses_infecting_bacterial_cells_%2C_Bacterial_viruses-nobeastsofierce_1f9734ecc24b48b18edf321551d48376-620x480.jpg",
    },
    {
      _id : "4",
      title: "Coagulants",
      description: "Coagulants are used to remove a wide variety of hazardous materials from water, ranging from organic matter and pathogens, to inorganics and toxic materials, like arsenic, chemical phosphorous and fluoride.",
      imageUrl: "https://cdn.prod.website-files.com/63217ccaf3f0f6ef21222235/6374cb304e982ab215429731_Anticoagulants.png",
    },
    {
      _id : "5",
      title: "Sludge Thickeners",
      description: "Sludge thickening is important because it is the process used in wastewater treatment centers to increase the solids concentration and decrease the free water.",
      imageUrl: "https://www.lzzgchina.com/wp-content/uploads/2021/06/deep-cone-thickener.jpg",
    },
    {
      _id : "6",
      title: "Polyelectrolytes",
      description: "Polyacrylamides are very high gramme-molecular weight polymers used as flocculants.",
      imageUrl: "https://cdn.rheonics.com/wp-content/uploads/2021/12/wastewater-management.jpeg",
    },
    {
      _id : "7",
      title: "Scale Inhibitors",
      description: "Scale inhibitors (SIs) are a class of specialty chemicals that are used to slow or prevent scaling in water systems. Oilfield scaling is the precipitation and accumulation of insoluble crystals (salts) from a mixture of incompatible aqueous phases in oil processing systems.",
      imageUrl: "https://content.jdmagicbox.com/quickquotes/images_main/cooling-water-treatment-ax-3012-scale-corrosion-inhibitor-377301647-t5kb0.jpg?impolicy=queryparam&im=Resize=(360,360),aspect=fit",
    },
  ];


  export const hydroTRandPChemicals = [
    {
      _id : "1",
      title: "Corrosion Inhibitors",
      description: "Corrosion Inhibitors are used to stop rusting and anodic corrosion of metals. This is generally done via the coating of the metal surface with a chromate layer.",
      imageUrl: "https://www.watchwater.de/wp-content/uploads/2019/01/20180713.jpg",
    },
    {
      _id : "2",
      title: "Oxygen Scavengers",
      description: "Oxygen scavengers can be used as CIs to react with dissolved oxygen in the environment and can help in preventing cathodic corrosion.",
      imageUrl: "https://www.jaydinesh.com/wp-content/uploads/2022/09/oxygen-scavengers-1.jpg",
    },
    {
      _id : "3",
      title: "Demulsifiers",
      description: " Demulsification is used for the separation of water from crude oil. The type of demulsifier selected depends on the type of emulsion, either oil-in-water or water-in-oil.",
      imageUrl: "https://s.alicdn.com/@sc04/kf/HLB11P8.S4YaK1RjSZFnq6y80pXaZ.jpg_720x720q50.jpg",
    },
    {
      _id : "4",
      title: "Scale Inhibitors",
      description: "Chemicals that are used to slow or prevent scaling in water systems.",
      imageUrl: "https://image.made-in-china.com/318f0j00CaHUbwugsDzW/e8604c27ea169819108618d85f4ad9d6-mp4.webp",
    },
    {
      _id : "5",
      title: "Biocides",
      description: "Biocides are used to preserve goods, stop pests like insects or rodents and control viruses, bacteria and fungi through a chemical or biological action.",
      imageUrl: "https://www.foodnavigator.com/var/wrbm_gb_food_pharma/storage/images/_aliases/wrbm_large/5/3/2/9/2909235-1-eng-GB/Improper-use-of-biocides-in-food-production-may-be-public-health-risk-say-researchers.jpg",
    },
    {
      _id : "6",
      title: "H2S Scavengers",
      description: "A hydrogen sulfide (H2S) scavengers is a specialized chemical or fuel additive widely used in hydrocarbon and chemical processing facilities. These specialized chemicals react selectively with and remove H2S to help meet product and process specifications. These H2S scavenger are generally referred to as gas sweetners.",
      imageUrl: "https://www.bakerhughes.com/sites/bakerhughes/files/styles/product_hero/public/2020-11/petrosweet-h2s-scavenger-solutions.png?itok=_g256AlH",
    },
  ];


  export const petrolAll = [
    {
      _id : "1",
      title: "Petrol",
      description: "Use our quality petroleum products to propel your vehicles, to heat buildings, and to produce electricity.",
      imageUrl: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/oil-barrels.jpg",
    },
    {
      _id : "2",
      title: "Diesel",
      description: "Diesel fuel is used in farms, diesel-engine generators, trains, buses, boats, and other vehicles etc.",
      imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Red_diesel_tank.jpg/640px-Red_diesel_tank.jpg",
    },
    {
      _id : "3",
      title: "Kerosene",
      description: "Cooking, lighting, and heating are the main household services provided by kerosene, although there are kerosene refrigerators and other appliances in some areas.",
      imageUrl: "https://d2cbg94ubxgsnp.cloudfront.net/Pictures/480x270/5/9/6/137596_shutterstock_1090299185.jpg",
    },
    {
      _id : "4",
      title: "Black Oil",
      description: "Petroleum oils are characterised as either black or white (clean). Black oils include crude oil, furnace oil, fuel oil, tar and asphalt. White oils include benzene, kerosene, and gasoline.",
      imageUrl: "https://hjoil.com.tw/upload//imgDB/S_20210525152306d1x7.jpg",
    },
  ];
  
 