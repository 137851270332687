import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { createPCard } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import styles from "../../styles/styles";
import Spinner from "../Spinner/Spinner";

const CreatePCard = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imageURL, setImageURL] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product Card created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newForm = new FormData();

    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("cardNumber", cardNumber);
    newForm.append("imageURL", imageURL);
    newForm.append("shopId", seller._id);
    dispatch(
      createPCard({
        name,
        description,
        cardNumber,
        imageURL,
        shopId: seller._id,
      })
    );
    setIsLoading(false);
  };

  return (
    <>
    {seller && seller?.role === "ProSeller" ? (
      <>
      {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create PCard</h5>
      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
          ></textarea>
        </div>

        <br />
        <div>
          <label className="pb-2">
            Card Number: <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="cardNumber"
            value={cardNumber}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="Enter card number..."
          />
        </div>
        <br />       
       
        <div>
          <label className="pb-2">Image URL</label>
          <input
            type="text"
            name="imageURL"
            value={imageURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setImageURL(e.target.value)}
            placeholder="Enter your cover..."
          />
        </div>
        <br />
        <br />
          <div>
            <input
              type="submit"
              value="Create"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <br />
      </form>
    </div>
    )}
      </>
    ) : (
      <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
      Access Denied! Only Admin Can List Products.
      </h1>
    )}
    
    </>
    
  );
};

export default CreatePCard;
