import React from "react";
import {  AiOutlineFolderAdd, } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo1 from '../../../Assests/images/logo-light.png'

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
 

  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            src={logo1}
            width='80px'
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4 justify-between gap-[20px]">
        <Link to={`/dashboard`}>
            <h5 className="text-[blue]">Admin</h5>
          </Link>        
                          
          <Link to={`/`}>
            <h5 className="text-[blue] ml-4">Home</h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
