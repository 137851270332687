import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard6 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle6CategoryCard6";
import SingleProductPage6 from "../pages/SingleProductPage6";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page6 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard6 />
        <SingleProductPage6 />
        
        <Footer />
    </div>
    );
  };

export default Page6;
