import React from "react";
import { useSelector } from "react-redux";


const DashboardHero = () => {
  const { seller } = useSelector((state) => state.seller);    
 
  
  return (
    <div className="w-full p-8">
      <h3 className="text-[22px] font-Poppins pb-2">Admin Panel {seller && seller?.role === "ProSeller" ? <span className="text-[12px] text-[blue]">Pro</span> : <span className="text-[12px] text-[blue]">Basic</span>}</h3>
      <p>Use the side bar buttons to list, edit and delete a product.</p>
      <br />
      </div>
  );
};

export default DashboardHero;

