import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard7 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle7CategoryCard7";
import SingleProductPage7 from "../pages/SingleProductPage7";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page7 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard7 />
        <SingleProductPage7 />
        
        <Footer />
    </div>
    );
  };

export default Page7;
