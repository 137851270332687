import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import FlowAssurance from "./AllProductPages/FlowAssurance";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";

const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://www.rcesoilandgastraining.org/training/5bfe04b95352eb559764b9aa364be9c7.jpg"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            Flow assurance includes thermal investigation of pipelines, making sure the temperature is above the hydrate's formation temperature. Other important aspects of flow assurance are the estimation of stable production limits, and evaluation of erosion due to sand and corrosion in pipelines and equipment. <br /> <br />
            Flow assurance issues such as hydrates, wax deposition are one of the important areas being studied today due to the high cost of deepwater exploration and production. The focus of flow assurance analysis and design has often been from flowline entry to the initial separator at the production facility.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const FlowAssurancePage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <FlowAssurance />
        
        <Footer />
    </div>
    );
  };

export default FlowAssurancePage;
