import React from 'react';
import { Link, useParams } from "react-router-dom";
import styles from "../../../styles/styles";
import { drillingCFData } from "../../../static/data";
import DrillSingleCard from '../ProductCard/DrillSingleCard';

function Hero4Drill() {

       

  return (
    <>
    <div>
      
       <div className={`${styles.section} bg-[#fff] p-4`}>
       <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {drillingCFData && drillingCFData.map((i, index) => <DrillSingleCard data={i} key={index} />)}
            </div>
      </div>
      
   
        </div>
     </>
    );
}

export default Hero4Drill
