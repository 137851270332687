import React from 'react';
import styles from "../../../styles/styles";
import { chemicalDegreasers } from "../../../static/data";
import DegreaserSingleCard from '../ProductCard/DegreaserSingleCard';

function Hero4Degreaser() {
       
  return (
    <>
    <div>
      
       <div className={`${styles.section} bg-[#fff] p-4`}>
       <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {chemicalDegreasers && chemicalDegreasers.map((i, index) => <DegreaserSingleCard data={i} key={index} />)}
            </div>
      </div>
      
   
        </div>
     </>
    );
}

export default Hero4Degreaser
