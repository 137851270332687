import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";
import WaterTChemicals from "./AllProductPages/WaterTChemicals";

const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://www.veoliawatertechnologies.co.uk/sites/default/files/styles/3_4_width_image/public/images/pages/news-page/WTC_Hydrex_60504---Veolia-Hydrex-%285%29-Logo-correction-%2821%29.jpg?itok=TTjShA2U"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            As a solvent for chemical reactants, water serves as a medium for many chemical synthesis and processing applications. Substances can be purified by dissolving them in water, then evaporating some of the water off to leave a purified form of the substance; some salts are purified by this means. <br /> <br />
            Disinfectants can be used in various industries. Ozone is used in the pharmaceutical industry, for drinking water preparation, for treatment of process water, for preparation of ultra-pure water and for surface disinfection. Chlorine dioxide is used primarily for drinking water preparation and disinfection of piping.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const WaterTChemicalsPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <WaterTChemicals />
        
        <Footer />
    </div>
    );
  };

export default WaterTChemicalsPage;
