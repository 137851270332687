import React from "react";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";


const ProductDetails = ({ data, seller }) => { 

  return (
    <>
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data.imageURL}`}
                  alt=""
                  className="w-[80%]"
                />
              </div>
              <div className="w-full 800px:w-[50%] pt-3">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1><br />
                
                <p className="py-2 text-[18px] whitespace-pre-line">
                {data.description}
                </p>

                <div className="flex">                                        
                <div className={`${styles.button}`}>
                <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Inquire Now
                  </h1>
                </Link> 
                </div>

                {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-product/${data._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Product
                  </h1>
                </Link> 
                </div>}
          </div>
         </div>              
        </div>
      </div> <br />

                    
          <br />
          <br />
        </div>
      ) : null}
    </div>
   </>
    
  );
};


export default ProductDetails;
