import React from "react";
import { Link } from "react-router-dom";

const DrillSingleCard = ({ data }) => { 

  return (
    <>
      <div className="w-full">
       <Link to={`/driling-and-completion-fluid-additives/${data._id}`}>
       <p className="pb-1 text-[#000]">
            {data.title}
        </p>
        </Link>
       </div>
    </>
  );
};

export default DrillSingleCard