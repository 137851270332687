import React from "react";
import FlowAssurancePDetailCard from "../../components/Route/ProductCard/FlowAssurancePDetailCard";
import styles from "../../styles/styles";
import { flowAssuranceData } from "../../static/data";


const FlowAssurance = () => {  

  return (
  <>
  <div>
    
     <div className={`${styles.section} bg-[#fff] p-4`}>
     <h1 className="text-[25px] font-[600] mb-3">Flow Assurance Products:</h1>

         <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {flowAssuranceData && flowAssuranceData.map((i, index) => <FlowAssurancePDetailCard data={i} key={index} />)}
          </div>
        <br />
          {flowAssuranceData && flowAssuranceData.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No products Found!
            </h1>
          ) : null}
        </div>
    
 
      </div>
   </>
  );
};

export default FlowAssurance;
