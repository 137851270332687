import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";
import HydroTRandPChemicals from "./AllProductPages/HydroTRandPChemicals";

const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://www.sulzer.com/-/media/images/applications/oil-gas-chemicals/downstream/refining/hydrotreating_unit.jpg?mw=827&la=en&hash=273CE8BBBD3EF45629700FC8119E2D0B"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            Hydrostatic tests are conducted by filling an object with water and pressurizing it to test for strength and leaks. The ability to detect leaks in an object is one of the most important reasons why this type of testing should be performed regularly. <br /> <br />
            Hydrostatic testing of hazardous liquid pipelines requires testing to at least 125% of the maximum operating pressure (MOP), for at least 4 continuous hours, and an additional 4 hours at a pressure of at least 110% of MOP if the piping is not visible.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const HydroTRCPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <HydroTRandPChemicals />
        
        <Footer />
    </div>
    );
  };

export default HydroTRCPage;
