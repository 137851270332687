import React from "react";
//import Footer from "../../components/Layout/Footer";
import EditProductHeader from "../../components/Shop/EditProductHeader";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
//import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";

const EditProductHeaderPage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-center w-full">
       <EditProductHeader />
      </div>
    </div>
  );
};

export default EditProductHeaderPage;
