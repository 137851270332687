export const sliderData = [
    {
      image1: "https://i.ibb.co/5LNjqmv/1b.jpg",
      image2: "https://i.ibb.co/JBvt2QM/1a.jpg", 
      },
    {
      image1: "https://i.ibb.co/1JgxFgV/2b.jpg",
      image2: "https://i.ibb.co/0Yfn9rz/2a.jpg",
      },
    {
      image1: "https://i.ibb.co/S5wJQQP/3b.jpg",
      image2: "https://i.ibb.co/pWKf1JW/3a.jpg",
      },
      {
        image1: "https://i.ibb.co/hCwcGM7/4b.jpg",
        image2: "https://i.ibb.co/qxCcqHd/4a.jpg",
        },
      {
        image1: "https://i.ibb.co/wLfRV3z/5b.jpg",
        image2: "https://i.ibb.co/vPjvgt4/5a.jpg",
        },
    
  ];
