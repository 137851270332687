import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard1 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle1CategoryCard1";
import SingleProductPage1 from "../pages/SingleProductPage1";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page1 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard1 />
        <SingleProductPage1 />
        
        <Footer />
    </div>
    );
  };

export default Page1;
