import React from "react";
import tPic1 from "../Assests/images/tPic1.jpg"
import tPic2 from "../Assests/images/tPic2.jpg"
import tPic3 from "../Assests/images/tPic3.jpg"
import tPic4 from "../Assests/images/tPic4.jpg"
import { Link } from "react-router-dom";
import styles from "../styles/styles";



const HorizontalCardNew2 = () => {

       
  return (
    <div className="bg-[#fff] px-10 py-12 grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] my-4">
             
        <div className="w-full min-h-[400px] bg-[#fcf8fa] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic4}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000] mt-3 font-[300]">
          <span className="text-[17px] font-[600]">OHK DEGREASER</span> is a powerful solvent based degreaser that actually dissolves grease and oil.
        </h5> 
        <Link to="mailto:info@ohkoil.com" target="_blank" className="inline-block">
            <div className='mt-2'>
                 <h5 className="text-[blue] font-[Poppins] !text-[14px] ml-4">
                 Get a Quote
                 </h5>
            </div>
        </Link>          
        </div>


        <div className="w-full min-h-[400px] bg-[#fcf8fa] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic3}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000] mt-3 font-[300]">
        <span className="text-[17px] font-[600]">OHK RIG WASH</span> is a mild liquid alkaline degreaser designed to provide the most basic surface cleaning.
        </h5>
        <Link to="mailto:info@ohkoil.com" target="_blank" className="inline-block">
            <div className='mt-2'>
                 <h5 className="text-[blue] font-[Poppins] !text-[14px] ml-4">
                 Get a Quote
                 </h5>
            </div>
        </Link>             
        </div>


        <div className="w-full min-h-[400px] bg-[#fcf8fa] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic1}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000] mt-3 font-[300]">
        <span className="text-[17px] font-[600]">OHK SODIUM HYPOCHLORITE SOLUTION.</span> This is a compound that can be effectively used for water purification.
        </h5>
        <Link to="mailto:info@ohkoil.com" target="_blank" className="inline-block">
            <div className='mt-2'>
                 <h5 className="text-[blue] font-[Poppins] !text-[14px] ml-4">
                 Get a Quote
                 </h5>
            </div>
        </Link>          
       </div>


        <div className="w-full min-h-[400px] bg-[#fcf8fa] rounded-lg shadow-lg px-3 py-1 relative cursor-pointer">
        <img
            src={tPic2}
            alt="worker"
            className="w-full object-contain pt-2"
          />
        <h5 className="px-3 text-[16px] text-[#000] mt-3 font-[300]">
        <span className="text-[17px] font-[600]">OHK TEEPOL</span> is a multi purpose detergent. It cleans all surface to a bright finish and completely removes all grease.
           </h5> 
           <Link to="mailto:info@ohkoil.com" target="_blank" className="inline-block">
            <div className='mt-2'>
                 <h5 className="text-[blue] font-[Poppins] !text-[14px] ml-4">
                 Get a Quote
                 </h5>
            </div>
        </Link>           
        </div>
        </div>
          
    );
};

export default HorizontalCardNew2;
