import React, { useState, useEffect } from 'react';
import styles from "../../../styles/styles";
import { server } from "../../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerX from "../../../components/Spinner/SpinnerX";

function Hero3b() {

  const { seller } = useSelector((state) => state.seller);
  const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/pcard/get-all-pcards`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.pcards);
    })
   }, []); 

   const datafetch6 = dataTU && dataTU.filter((i) => i.cardNumber === 6);

   const data6 = datafetch6[0];

   const datafetch7 = dataTU && dataTU.filter((i) => i.cardNumber === 7);

   const data7 = datafetch7[0];

   
    
  return (
    <div className='w-full'>      

        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          {data6 && data6.name ? data6.name : <SpinnerX />}
        </h1>

        <br />
        <img
            src={data6 && data6.imageURL}
            alt=""
            className="w-[90%] p-3"
          />
        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
        {data6 && data6.description ? data6.description : <SpinnerX />}
        </p>
               
        <div className='flex px-4 mt-5'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Inquire now
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data6 && data6._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
        </div>


        <div className='w-full 800px:w-[50%]'>
        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          {data7 && data7.name ? data7.name : <SpinnerX />}
        </h1>
        <br />
        <img
            src={data7 && data7.imageURL}
            alt=""
            className="w-[90%] p-3"
          />

        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">{data7 && data7.description ? data7.description : "loading..."}</p>
        
        <div className='flex px-4 mt-5'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Inquire now
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data7 && data7._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
        <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3b
