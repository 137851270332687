import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Rigs from "./AllProductPages/Rigs";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";


const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://cdn.businessday.ng/2021/08/OML-11-1.png"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            The term "rig" therefore generally refers to the complex equipment that is used to penetrate the surface of the Earth's crust. Small to medium-sized drilling rigs are mobile, such as those used in mineral exploration drilling, blast-hole, water wells and environmental investigations. <br /> <br />
            The various types of offshore rigs include barges, submersibles, platforms, jackups, and floaters (the latter of which include semisubmersibles and drill ships).
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const RigsPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <Rigs />
        
        <Footer />
    </div>
    );
  };

export default RigsPage;
