import React from "react";
import { Link } from "react-router-dom";

const WaterTChemicalsSingleCard = ({ data }) => { 
  return (
    <>
      <div className="w-full">
       <Link to={`/water-treatment-chemicals/${data._id}`}>
       <p className="pb-1 text-[#000]">
            {data.title}
        </p>
        </Link>
       </div>
    </>
  );
};

export default WaterTChemicalsSingleCard