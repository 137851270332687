import React from "react";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);

  const enableCreateProductHeaderandPCard = false;

  return (
    <div className="w-full h-[90vh] bg-white shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={20}
            color={`${active === 1 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>
           

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link to="/dashboard-products" className="w-full flex items-center">
          <FiPackage size={20} color={`${active === 2 ? "blue" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>
      )}
      

      {seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd
            size={20}
            color={`${active === 3 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create Product
          </h5>
        </Link>
      </div>
      )}


      {enableCreateProductHeaderandPCard && seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-productheader"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd
            size={20}
            color={`${active === 4 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Product Header
          </h5>
        </Link>
      </div>
      )}


        {enableCreateProductHeaderandPCard && seller && seller?.role === "ProSeller" && (
        <div className="w-full flex items-center p-4">
        <Link
          to="/dashboard-create-pcard"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd
            size={20}
            color={`${active === 5 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Create PCard
          </h5>
        </Link>
      </div>
      )}                
      
      
      <div className="w-full flex items-center p-4">
        <Link to="/settings" className="w-full flex items-center">
          <CiSettings
            size={20}
            color={`${active === 6 ? "blue" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[blue]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
