import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Store from "./redux/store";
import { Provider } from "react-redux";


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr', 'hi'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>OHK Oil Loading..</h3>
  </div>
)


  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
 <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
  <Provider store={Store}>
   <App />
   </Provider>
  </React.StrictMode>
  </Suspense>,
);


