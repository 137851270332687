import React from "react";
//import Footer from "../../components/Layout/Footer";
import EditHandle from "../../components/Shop/EditHandle";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
//import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";

const EditHandlePage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-center w-full">
       <EditHandle />
      </div>
    </div>
  );
};

export default EditHandlePage;
