import React, { useEffect, useState } from "react";
import SingleProductCard from "../components/Route/ProductCard/SingleProductCard";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import SpinnerX from "../components/Spinner/SpinnerX";


const SingleProductPage7 = () => {

    const {allProducts} = useSelector((state) => state.products);
    const [data, setData] = useState([]);

    useEffect(() => {
        const d =
          allProducts && allProducts.filter((i) => i.category === "Hydro Testing/Refinery and Process Chemicals");
          setData(d);
     }, [allProducts]);
    
      console.log(data);
  

  return (
  <>
  <div>
    
     <div className={`${styles.section} bg-[#fff] p-4`}>
     <h1 className="text-[25px] font-[600] mb-3">Hydro Testing/Refinery and Process Chemicals:</h1>

     <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {data && data.map((i, index) => <SingleProductCard data={i} key={index} />)}
          </div>
        <br />
          {data && data.length === 0 ? (
            <SpinnerX />
          ) : null}
        </div>
    
 
      </div>
   </>
  );
};

export default SingleProductPage7;
