import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import styles from "../../styles/styles";
import axios from "axios";
import { loadHandle } from "../../redux/actions/sellers";
import { toast } from "react-toastify";

const EditHandle = () => {
  const { handle } = useSelector((state) => state.seller);
  const [facebook, setFacebook] = useState(handle && handle.facebook);
  const [linkedin, setLinkedin] = useState(handle && handle.linkedin);
  const [instagram, setInstagram] = useState(handle && handle.instagram);
  const [youtube, setYoutube] = useState(handle && handle.youtube);
  
  const dispatch = useDispatch();
  
  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/handle/update-handle`,
        {
          facebook,
          linkedin,
          instagram,
          youtube,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Handles updated succesfully!");
        dispatch(loadHandle());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="flex w-full 800px:w-[80%] flex-col justify-center my-5">
      <h5 className="text-[30px] font-Poppins text-center">Edit Social Handles</h5>
        
        {/*Socila Handles info */}
        <form
          aria-aria-required={true}
          className="flex flex-col items-center"
          onSubmit={updateHandler}
        >
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Facebook:</label>
            </div>
            <input
              type="name"
              placeholder={`${handle.facebook}`}
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>

          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Linkedin:</label>
            </div>
            <input
              type="name"
              placeholder={`${handle.linkedin}`}
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>

          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">Instagram:</label>
            </div>
            <input
              type="name"
              placeholder={`${handle.instagram}`}
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>

          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <div className="w-full pl-[3%]">
              <label className="block pb-2">YouTube:</label>
            </div>
            <input
              type="name"
              placeholder={`${handle.youtube}`}
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
            />
          </div>
                    
          
          <div className="w-[100%] bg-[black] text-[#fff] flex items-center flex-col 800px:w-[50%] mt-5 !cursor-pointer">
            <input
              type="submit"
              value="Update Handles"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHandle;
