import axios from "axios";
import { server } from "../../server";

// create product
export const createProduct =
  (
    name,
    description,
    category,
    imageURL,
    shopId,
    
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/product/create-product`,
        name,
        description,
        category,
        imageURL,
        shopId,
        
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error.response.data.message,
      });
    }
  };

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};



// Update Product Info
export const updateProductInformation = (
        name,
        description,
        imageURL,
        productId,
    ) => async (dispatch) => {
    try {
      dispatch({
        type: "updateProductInfoRequest",
      });

      const { data } = await axios.put(
        `${server}/product/update-product/${productId}`,
        {
        name,
        description,
        imageURL,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateProductInfoSuccess",
        payload: {
          successMessage: "Product updated!",
          product: data.product,
        },
      });
    } catch (error) {
      dispatch({
        type: "updateProductInfoFailed",
        payload: error.response.data.message,
      });
    }
  };




  // create product header
export const createProductHeader =
  (
    description,
    category,
    imageURL,
    shopId,
    
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productheaderCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/productheader/create-productheader`,
        description,
        category,
        imageURL,
        shopId,
        
      );
      dispatch({
        type: "productheaderCreateSuccess",
        payload: data.productheader,
      });
    } catch (error) {
      dispatch({
        type: "productheaderCreateFail",
        payload: error.response.data.message,
      });
    }
  };




  // create product header
export const createPCard =
(
  name,
  description,
  cardNumber,
  imageURL,
  shopId,
  
) =>
async (dispatch) => {
  try {
    dispatch({
      type: "pcardCreateRequest",
    });

    const { data } = await axios.post(
      `${server}/pcard/create-pcard`,
  name,
  description,
  cardNumber,
  imageURL,
  shopId,
      
    );
    dispatch({
      type: "pcardCreateSuccess",
      payload: data.pcard,
    });
  } catch (error) {
    dispatch({
      type: "pcardCreateFail",
      payload: error.response.data.message,
    });
  }
};

