import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DrillingAndComFluidProduct from "../pages/AllProductPages/DrillingAndComFluidProduct";
import Hero3d from "../components/Route/Hero/Hero3d";
import styles from "../styles/styles";


const DrilPicHeader = () => {
    return (
        <div>  
            <br />    
    
          <div className={`${styles.section} p-4 800px:flex`}>
    
            <div className={`w-full 800px:w-[50%]`}>                
            <img
            src="https://msipipeprotection.com/wp-content/uploads/AdobeStock_139899101-1024x494.jpeg"
            alt=""
            className="w-full p-3"
          />    
            </div>

            <div className={`w-full 800px:w-[50%]`}>                
            <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba]">
            Improve drilling efficiency, wellbore stability, hole cleaning, lubricity, fluid loss control, and environmental compliance with our quality fluid additives. <br /> <br />
            The completion fluid additives include products designed for filtration control, viscosity, scavengers, surfactants, corrosion inhibition and gas-hydrate suppression. CBF are integral to successful completion operations and must be designed and engineered to specific downhole environmental and operating conditions.
            </p>  
            </div>
        </div>
          
        </div>
      )
}

const DrillingandCompPage = () => {
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <DrilPicHeader />
        <DrillingAndComFluidProduct />
        
        <Footer />
    </div>
    );
  };

export default DrillingandCompPage;
