import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../styles/styles";
import { hydroTRandPChemicals } from "../static/data";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero3d from "../components/Route/Hero/Hero3d";
import Hero4HydroTRC from "../components/Route/Hero/Hero4HydroTRC";


const HydroTRCSingleD = ({ data }) => { 

  return (
    <>
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data.imageUrl}`}
                  alt=""
                  className="w-[80%]"
                />
              </div>
              <div className="w-full 800px:w-[50%] pt-3">
                <h1 className={`${styles.productTitle}`}>{data.title}</h1><br />
                
                <p className="py-2 text-[18px] leading-2 whitespace-pre-line">
                {data.description}
                </p>

                <div className="flex">                                        
                <div className={`${styles.button}`}>
                <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Enquire
                  </h1>
                </Link> 
            </div>
          </div>

               </div>              
            </div>
            
          </div> <br />

                    
          <br />
          <br />
        </div>
      ) : null}
    </div>
   </>
    
  );
};


  const HydroTRCSingleDPage = () => {

  const [dataTU, setDataTU] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const data = hydroTRandPChemicals && hydroTRandPChemicals.find((iit) => iit._id === id);
    setDataTU(data);
  },[])
 
    return (
    <div>
        <Header activeHeading={2} />
  
        <Hero3d />
        <HydroTRCSingleD data={dataTU} />
        <Hero4HydroTRC />
        
        <Footer />
    </div>
    );
  };

export default HydroTRCSingleDPage;
