import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  
};

export const sellerReducer = createReducer(initialState, {
  LoadSellerRequest: (state) => {
    state.isLoading = true;
  },
  LoadSellerSuccess: (state, action) => {
    state.isSeller = true;
    state.isLoading = false;
    state.seller = action.payload;
  },
  LoadSellerFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isSeller = false;
  },

  
  // get all sellers --- For Open Use
  getAllSellersOpenRequest: (state) => {
    state.isLoading = true;
  },
  getAllSellersOpenSuccess: (state, action) => {
    state.isLoading = false;
    state.sellers = action.payload;
  },
  getAllSellerOpenFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // load handle
  LoadHandleRequest: (state) => {
    state.isLoading = true;
  },
  LoadHandleSuccess: (state, action) => {
    state.isLoading = false;
    state.handle = action.payload;
  },
  LoadHandleFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


  // load single pcard
  LoadSPCardRequest: (state) => {
    state.isLoading = true;
  },
  LoadSPCardSuccess: (state, action) => {
    state.isLoading = false;
    state.pcard = action.payload;
  },
  LoadSPCardFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


  // load single pcard
  LoadSPRequest: (state) => {
    state.isLoading = true;
  },
  LoadSPSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
  },
  LoadSPFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // load single productheader
  LoadSPHRequest: (state) => {
    state.isLoading = true;
  },
  LoadSPHSuccess: (state, action) => {
    state.isLoading = false;
    state.productheader = action.payload;
  },
  LoadSPHFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  });
