import axios from "axios";
import { server } from "../../server";

// load seller
export const loadSeller = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSellerRequest",
    });
    const { data } = await axios.get(`${server}/shop/getSeller`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadSellerSuccess",
      payload: data.seller,
    });
  } catch (error) {
    dispatch({
      type: "LoadSellerFail",
      payload: error.response.data.message,
    });
  }
};



// get all sellers --- Open
export const getAllSellersOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSellersOpenRequest",
    });

    const { data } = await axios.get(`${server}/shop/open-all-sellers`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSellersOpenSuccess",
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: "getAllSellerOpenFailed",
      payload: error.response.data.message,
    });
  }
};


// load handle
export const loadHandle = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadHandleRequest",
    });
    const { data } = await axios.get(`${server}/handle/getHandle`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadHandleSuccess",
      payload: data.handle,
    });
  } catch (error) {
    dispatch({
      type: "LoadHandleFail",
      payload: error.response.data.message,
    });
  }
};


// load single pcard
export const loadSPCard = (pcardId) => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSPCardRequest",
    });
    const { data } = await axios.get(`${server}/pcard/get-single-pcard/${pcardId}`, {
      withCredentials: true,
    });
    console.log("Request URL:", `${server}/pcard/get-single-pcard/${pcardId}`);
    dispatch({
      type: "LoadSPCardSuccess",
      payload: data.pcard,
    });
  } catch (error) {
    dispatch({
      type: "LoadSPCardFail",
      payload: error.response.data.message,
    });
  }
};

// load single product
export const loadSP = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSPRequest",
    });
    const { data } = await axios.get(`${server}/product/get-single-product/${productId}`, {
      withCredentials: true,
    });
    console.log("Request URL:", `${server}/pcard/get-single-pcard/${productId}`);
    dispatch({
      type: "LoadSPSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "LoadSPFail",
      payload: error.response.data.message,
    });
  }
};


// load single productheader
export const loadSPH = (productHId) => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSPHRequest",
    });
    const { data } = await axios.get(`${server}/productheader/get-single-productheader/${productHId}`, {
      withCredentials: true,
    });
    console.log("Request URL:", `${server}/pcard/get-single-pcard/${productHId}`);
    dispatch({
      type: "LoadSPHSuccess",
      payload: data.productheader,
    });
  } catch (error) {
    dispatch({
      type: "LoadSPHFail",
      payload: error.response.data.message,
    });
  }
};