import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard3 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle3CategoryCard3";
import SingleProductPage3 from "../pages/SingleProductPage3";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page3 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard3 />
        <SingleProductPage3 />
        
        <Footer />
    </div>
    );
  };

export default Page3;
