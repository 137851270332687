import React, { useState, useEffect } from 'react';
import styles from "../../../styles/styles";
import { server } from "../../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerX from "../../../components/Spinner/SpinnerX";

function Hero3c() {

  const { seller } = useSelector((state) => state.seller);
  const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/pcard/get-all-pcards`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.pcards);
    })
   }, []); 

   const datafetch8 = dataTU && dataTU.filter((i) => i.cardNumber === 8);

   const data8 = datafetch8[0];

   const datafetch9 = dataTU && dataTU.filter((i) => i.cardNumber === 9);

   const data9 = datafetch9[0];

 
    

  return (
    <div className='w-full'>      

        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          {data8 && data8.name ? data8.name : <SpinnerX />}
        </h1>
        <br />

        <img
            src={data8 && data8.imageURL}
            alt=""
            className="w-[90%] p-3"
          />

        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
        {data8 && data8.description ? data8.description : <SpinnerX />} 
        </p>
                
        <div className='flex px-4 mt-5'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data8 && data8._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>

        <br />
        </div>


        <div className='w-full 800px:w-[50%]'>

        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          {data9 && data9.name ? data9.name : <SpinnerX />}
        </h1>
        <br />
        <img
            src={data9 && data9.imageURL}
            alt=""
            className="w-[90%] p-3"
          />

        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
        {data9 && data9.description ? data9.description : <SpinnerX />}
        </p>
        
        <div className='flex px-4 mt-5'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Inquire now
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data9 && data9._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
      <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3c
