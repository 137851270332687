import React, { useEffect } from 'react'
import styles from "../styles/styles";
import logoo1 from "../Assests/logos/agip.png";
import logoo2 from "../Assests/logos/exonmobile.png";
import logoo2b from "../Assests/logos/chevron.png";
import logoo3 from "../Assests/logos/berean.png";
import logoo4 from "../Assests/logos/sap.png";
import logoo5 from "../Assests/logos/shell.png";
import "./companies.css";


function CompaniesScroll() {
    
  return (
    <div>
        <div className={`${styles.section} w-[90%] 800px:w-[70%] py-8`}>
        <div className={`${styles.heading} py-8`}>
          
         <section className="companies marquee">
          <div className="marquee__inner" aria-hidden="true">
          <img src={logoo1} alt="" className="companies__logo" />
          <img src={logoo2} alt="" className="companies__logo" />
          <img src={logoo2b} alt="" className="companies__logo" />
          <img src={logoo3} alt="" className="companies__logo" />
          <img src={logoo4} alt="" className="companies__logo" />
          <img src={logoo5} alt="" className="companies__logo" />
          
          
          <img src={logoo1} alt="totlesoft-pay" className="companies__logo" />
          <img src={logoo2} alt="appen" className="companies__logo" />
          <img src={logoo2b} alt="prolific" className="companies__logo" />
          <img src={logoo3} alt="fiverr" className="companies__logo" />
          <img src={logoo4} alt="freelance" className="companies__logo" />
          <img src={logoo5} alt="oneformer" className="companies__logo" />
          </div>
          </section>
          </div>
        </div>
    </div>
  )
}

export default CompaniesScroll