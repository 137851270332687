import React, { useEffect, useState } from 'react';
import styles from "../../../styles/styles";
import { server } from "../../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerX from "../../../components/Spinner/SpinnerX";


const ProductSIngleCategoryCard7 = () => {

    const { seller } = useSelector((state) => state.seller);
    const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/productheader/get-all-productheaders`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.productheaders);
    })
   }, []); 

   const datafetch = dataTU && dataTU.filter((i) => i.category === "Hydro Testing/Refinery and Process Chemicals");

   const data = datafetch[0];
   
   console.log("the data is: ")
   console.log(data)

    return (
      <>
      {data ? (
        <div>  
        <br />    

      <div className={`${styles.section} p-4 800px:flex`}>

        <div className={`w-full 800px:w-[50%]`}>                
        <img
        src={data?.imageURL}
        alt=""
        className="w-full p-3"
      />    
        </div>

        <div className={`w-full 800px:w-[50%]`}>                
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] pb-8 whitespace-pre-line">{data?.description}</p>

        {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
            <Link to={`/edit-product-header/${data && data._id}`}>
              <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
              Edit Header
              </h1>
            </Link> 
            </div>}  
        </div>
    </div>

    <br />
    </div>
      ) : (
        <SpinnerX />
      )}
      </>
      )
}

export default ProductSIngleCategoryCard7;