import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  ProductsPage,
  ProductDetailsPage,
  ContactPage,
  PrivacyPolicyPage,
  DrillingandCompPage,
  DrillingandCompPDetailPage,
  DrillingCSingleDPage,
  PhaseSepPage,
  PhaseSepPDetailPage,
  PhaseSepSingleDPage,
  RigsPage,
  RigsPDetailPage,
  RigsSingleDPage,
  FlowAssurancePage,
  FlowAssurancePDetailPage,
  FlowAssuranceSingleDPage,
  DegreaserPage,
  DegreaserPDetailPage,
  DegreaserSingleDPage,
  WaterTChemicalsPage,
  WaterTChemicalsPDetailPage,
  WaterTChemicalsSingleDPage,
  HydroTRCPage,
  HydroTRCPDetailPage,
  HydroTRCSingleDPage,
  PetrolAllPage,
  PetrolAllPDetailPage,
  PetrolAllSingleDPage,
  ShopCreatePage,
  ShopLoginPage,
 
  } from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopPreviewPage,
  ShopSettingsPage,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
  ShopCreateProductHeader,
  EditProductPage,
  EditProductHeaderPage,
  EditPCardPage,
  ShopCreatePCard,
  EditHandlePage,
  } from "./routes/ShopRoutes";
import { ToastContainer } from "react-toastify";
import { 
  loadSeller, 
  loadHandle, 
  loadSPCard, 
  loadSP,
  loadSPH,
} from "./redux/actions/sellers";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { ShopHomePage } from "./ShopRoutes.js";
import Store from "./redux/store";
import { getAllProducts } from "./redux/actions/product";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  
    useEffect(() => {
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(loadHandle());
    Store.dispatch(loadSPCard());
    Store.dispatch(loadSP());
    Store.dispatch(loadSPH());
    }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="/driling-and-completion-fluid-additives" element={<DrillingandCompPage />} />
        <Route path="/product/driling-and-completion-fluid-additives/:id" element={<DrillingandCompPDetailPage />} />
        <Route path="/driling-and-completion-fluid-additives/:id" element={<DrillingCSingleDPage />} />

        <Route path="/phase-separation" element={<PhaseSepPage />} />
        <Route path="/product/phase-separation/:id" element={<PhaseSepPDetailPage />} />
        <Route path="/phase-separation/:id" element={<PhaseSepSingleDPage />} />

        <Route path="/rigs" element={<RigsPage />} />
        <Route path="/product/rigs/:id" element={<RigsPDetailPage />} />
        <Route path="/rigs/:id" element={<RigsSingleDPage />} />

        <Route path="/flow-assurance" element={<FlowAssurancePage />} />
        <Route path="/product/flow-assurance/:id" element={<FlowAssurancePDetailPage />} />
        <Route path="/flow-assurance/:id" element={<FlowAssuranceSingleDPage />} />

        <Route path="/degreaser-and-disinfectant" element={<DegreaserPage />} />
        <Route path="/product/degreaser-and-disinfectant/:id" element={<DegreaserPDetailPage />} />
        <Route path="/degreaser-and-disinfectant/:id" element={<DegreaserSingleDPage />} />

        <Route path="/water-treatment-chemicals" element={<WaterTChemicalsPage />} />
        <Route path="/product/water-treatment-chemicals/:id" element={<WaterTChemicalsPDetailPage />} />
        <Route path="/water-treatment-chemicals/:id" element={<WaterTChemicalsSingleDPage />} />

        <Route path="/hydro-testing-and-process-chemicals" element={<HydroTRCPage />} />
        <Route path="/product/hydro-testing-and-process-chemicals/:id" element={<HydroTRCPDetailPage />} />
        <Route path="/hydro-testing-and-process-chemicals/:id" element={<HydroTRCSingleDPage />} />

        <Route path="/secondary-fuels" element={<PetrolAllPage />} />
        <Route path="/product/secondary-fuels/:id" element={<PetrolAllPDetailPage />} />
        <Route path="/secondary-fuels/:id" element={<PetrolAllSingleDPage />} />
      

 	
	{/* shop Routes */}
        <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        <Route path="/admin-register" element={<ShopCreatePage />} />
        <Route path="/admin-login" element={<ShopLoginPage />} />

        <Route path="/1/driling-and-completion-fluid-additives/products" element={<Page1 />} />
        <Route path="/2/phase-separation/products" element={<Page2 />} />
        <Route path="/3/rigs/products" element={<Page3 />} />
        <Route path="/4/flow-assurance/products" element={<Page4 />} />
        <Route path="/5/degreaser-and-disinfectant/products" element={<Page5 />} />
        <Route path="/6/water-treatment-chemicals/products" element={<Page6 />} />
        <Route path="/7/hydro-testing-and-process-chemicals/products" element={<Page7 />} />
        <Route path="/8/secondary-fuels/products" element={<Page8 />} />

        <Route
          path="/edit-product/:id"
          element={
            <SellerProtectedRoute>
              <EditProductPage />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/edit-product-header/:id"
          element={
            <SellerProtectedRoute>
              <EditProductHeaderPage />
            </SellerProtectedRoute>
          }
        />


        <Route
          path="/edit-pcard/:id"
          element={
            <SellerProtectedRoute>
              <EditPCardPage />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/edit-handle/:id"
          element={
            <SellerProtectedRoute>
              <EditHandlePage />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/shop/:id"
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        /> 

        <Route
          path="/dashboard-create-pcard"
          element={
            <SellerProtectedRoute>
              <ShopCreatePCard />
            </SellerProtectedRoute>
          }
        /> 
        <Route
          path="/dashboard-create-productheader"
          element={
            <SellerProtectedRoute>
              <ShopCreateProductHeader />
            </SellerProtectedRoute>
          }
        />          
        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />     
        </Routes>   

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;


