import React from "react";
import { Link } from "react-router-dom";

const RigsSingleCard = ({ data }) => { 

  return (
    <>
      <div className="w-full">
       <Link to={`/rigs/${data._id}`}>
       <p className="pb-1 text-[#000]">
            {data.title}
        </p>
        </Link>
       </div>
    </>
  );
};

export default RigsSingleCard