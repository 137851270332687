import React from "react";
//import Footer from "../../components/Layout/Footer";
import EditProduct from "../../components/Shop/EditProduct";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
//import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";

const EditProductPage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-center w-full">
       <EditProduct />
      </div>
    </div>
  );
};

export default EditProductPage;
