import React from "react";
import PhaseSepPDetalCard from "../../components/Route/ProductCard/PhaseSepPDetalCard";
import styles from "../../styles/styles";
import { phaseSP } from "../../static/data";


const PhaseSeparationP = () => {
  

  return (
  <>
  <div>
    
     <div className={`${styles.section} bg-[#fff] p-4`}>
     <h1 className="text-[25px] font-[600] mb-3">Phase Separation Products:</h1>

         <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {phaseSP && phaseSP.map((i, index) => <PhaseSepPDetalCard data={i} key={index} />)}
          </div>
        <br />
          {phaseSP && phaseSP.length === 0 ? (
            <h1 className="text-center w-full pb-[100px] text-[20px]">
              No products Found!
            </h1>
          ) : null}
        </div>
    
 
      </div>
   </>
  );
};

export default PhaseSeparationP;
