import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
import { server } from "../../server";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";


const EditProductHeader = () => {
    
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/productheader/get-all-productheaders`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.productheaders);
    })
   }, []);
  
    const productheader = dataTU && dataTU.find((i) => i._id === id);

    console.log("productheader data today is: ")
    console.log(productheader);
    
    
   const [description, setDescription] = useState(
    productheader && productheader.description ? productheader.description : ""
    );
    const [imageURL, setImageURL] = useState(productheader && productheader.imageURL ? productheader.imageURL : "" );

  
  const updateHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    await axios
      .put(
        `${server}/productheader/update-productheader/${id}`,
        {
          description,
          imageURL,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success("Product Header updated succesfully!");
        navigate("/dashboard");
        window.location.reload(true);
        })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
    {isLoading ? <Spinner /> : (
      <div className="w-[90%] 800px:w-[60%] bg-white  shadow h-[80vh] rounded-[4px] px-8 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Edit Product Header</h5>
      <p className="text-center">NOTE: if you didn't edit a field also ensure to recopy and paste back to its appropriate box before submiting.</p>
      {/* update product form */}
      <form onSubmit={updateHandler}>
        <br />
         <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 whitespace-pre-line sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder={productheader && productheader.description ? productheader.description : ""}
          ></textarea>
        </div>  
        <br />

        <p className="text-[blue] px-3 whitespace-pre-line">{productheader && productheader.description ? productheader.description : ""}</p>    

        <br />
        <div>
          <label className="pb-2">Image URL</label>
          <input
            type="text"
            name="imageURL"
            value={imageURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setImageURL(e.target.value)}
            placeholder={productheader && productheader.imageURL ? productheader.imageURL : ""}
          />
        </div>
        <br />

        <p className="text-[blue] px-3">{productheader && productheader.imageURL ? productheader.imageURL : ""}</p> 

        <br />
          <div>
            <input
              type="submit"
              value="Update"
              className="mt-2 cursor-pointer text-[#fff] bg-[black] text-center w-full px-3 h-[35px] rounded-lg sm:text-sm"
            />
          </div>
          <br />
      </form>
    </div>
    )}
      
    </>
  );
};

export default EditProductHeader;
