import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard2 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle2CategoryCard2";
import SingleProductPage2 from "../pages/SingleProductPage2";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page2 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard2 />
        <SingleProductPage2 />
        
        <Footer />
    </div>
    );
  };

export default Page2;
