import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import DrillingAndComFluidProduct from "../pages/AllProductPages/DrillingAndComFluidProduct";
import PhaseSeparationP from "../pages/AllProductPages/PhaseSeparationP";
import Rigs from "../pages/AllProductPages/Rigs";
import FlowAssurance from "./AllProductPages/FlowAssurance";
import Degreaser from "./AllProductPages/Degreaser";
//import DisAndMultiDetergent from "./AllProductPages/DisAndMultiDetergent";
import WaterTChemicals from "./AllProductPages/WaterTChemicals";
import HydroTRandPChemicals from "./AllProductPages/HydroTRandPChemicals";
import PetrolAll from "./AllProductPages/PetrolAll";
import Hero3d from "../components/Route/Hero/Hero3d";
import SingleProductPage1 from "./SingleProductPage1";
import SingleProductPage2 from "./SingleProductPage2";
import SingleProductPage3 from "./SingleProductPage3";
import SingleProductPage4 from "./SingleProductPage4";
import SingleProductPage5 from "./SingleProductPage5";
import SingleProductPage6 from "./SingleProductPage6";
import SingleProductPage7 from "./SingleProductPage7";
import SingleProductPage8 from "./SingleProductPage8";

const ProductsPage = () => {
 
  return (
  <div>
      <Header activeHeading={2} />

      <Hero3d />
      <SingleProductPage1 /> 
      <SingleProductPage2 />
      <SingleProductPage3 />
      <SingleProductPage4 />
      <SingleProductPage5 />
      <SingleProductPage6 />
      <SingleProductPage7 />
      <SingleProductPage8 />    

      <Footer />
  </div>
  );
};

export default ProductsPage;




/*

<div>
      <Header activeHeading={2} />

      <Hero3d />
      <DrillingAndComFluidProduct />
      <PhaseSeparationP />
      <Rigs />
      <FlowAssurance />
      <Degreaser />
      
      <WaterTChemicals />
      <HydroTRandPChemicals />
      <PetrolAll />     

      <Footer />
  </div>

  */