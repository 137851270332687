import React, { useState, useEffect } from 'react';
import styles from "../../../styles/styles";
import { server } from "../../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerX from "../../../components/Spinner/SpinnerX";

function Hero3() {
  const { seller } = useSelector((state) => state.seller);
  const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/pcard/get-all-pcards`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.pcards);
    })
   }, []); 

   const datafetch1 = dataTU && dataTU.filter((i) => i.cardNumber === 1);

   const data1 = datafetch1[0];
   
   
   const datafetch2 = dataTU && dataTU.filter((i) => i.cardNumber === 2);

   const data2 = datafetch2[0];

   const datafetch3 = dataTU && dataTU.filter((i) => i.cardNumber === 3);

   const data3= datafetch3[0];

   const datafetch4 = dataTU && dataTU.filter((i) => i.cardNumber === 4);

   const data4 = datafetch4[0];
  
   

  return (
    <div className='w-full'>

    <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
      <div className={`${styles.section} w-full`}>
      <h1
          className={`!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
         OCEAN HAWK OIL RESOURCES LTD. <span className='text-[16px]'>RC-473600</span>
        </h1>

        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Ocean Hawk Oil Resources Ltd is an Oil & Gas marketing and haulage company based in Port Harcort Nigeria.
        We are general suppliers of all types of oil field production chemicals, rig washes and degreasers.
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        We also supply petrol (PMS), diesel (AGO), kerosene (DPK), low pour fuel oil (LPFO) and oil field drilling rigs,
        550HP, 750HP, 1000HP, 1500HP, 2000HP, AC/DC, Skid Mounted, Truck Mounted, Desert Fast Moving, Drilling tools, Addictives and Fluids.
        </p>
      </div>
    </div>
    <br />
   

      

      <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>
        <div className={`${styles.section} w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#fff] px-4 py-2 font-[600] bg-[#c89b2a] rounded-xl w-[300px]`}
        >
         OUR VISION
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        To become a global producer and supplier of petroleum products, oil production tools and oil field chemicals in Nigeria and beyond. 
       </p>
        <br />
        </div>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className={`!text-[25px] leading-[0.9] 800px:text-[80px] text-[#fff] px-4 py-2 font-[600] bg-[#012] rounded-xl w-[300px]`}
        >
         MISSION STATEMENT
        </h1>
        <p className="pt-2 text-[16px] font-[Poppins] text-[#000000ba]">
        Our focus is on delivering excellent products and services to our customers without compromising our commitment to best 
        business practices health and safety matters and the promotion of a clean environment. 
       </p>
        <br />
        
        </div>
        </div>


        <div className={`${styles.section} w-[90%] 800px:w-[70%] 800px:flex my-4`}>

        <div className={`${styles.section} 800px:ml-4 w-full 800px:w-[50%]`}>
        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          OUR PRODUCTS<br /> & SERVICES
        </h1>
        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba] uppercase px-4">
          {data1 && data1.name ? data1.name : <SpinnerX />}:
        </h4>

        <br />
        <img
            src={data1 && data1.imageURL}
            alt=""
            className="w-[90%] p-3"
          />
        
        <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
        {data1 && data1.description ? data1.description : <SpinnerX />} 
        </p>
        
        <div className='flex px-4'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data1 && data1._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>

        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba] px-4">
        {data2 && data2.name ? data2.name : <SpinnerX />}:
        </h4>
        
        <br />
        <img
            src={data2 && data2.imageURL}
            alt=""
            className="w-[90%] p-3"
          />


      <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
      {data2 && data2.description ? data2.description : <SpinnerX />}
      </p>
        
        <div className='flex px-4'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data2 && data2._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>

        <br />
        </div>


        <div className='w-full 800px:w-[50%]'>

        <h4 className="pt-3 text-[25px] font-[Poppins] font-[500] text-[#000000ba] px-4">
        {data3 && data3.name ? data3.name : <SpinnerX />}:
        </h4>
        
        <br />
        <img
            src={data3 && data3.imageURL}
            alt=""
            className="w-[90%] p-3"
          />


      <p className="pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6">
      {data3 && data3.description ? data3.description : <SpinnerX />}
      </p>
        
        <div className='flex px-4'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data3 && data3._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
              <br />
              <br />

        <h1
          className="!text-[35px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600] px-4"
        >
          {data4 && data4.name ? data4.name : <SpinnerX />}
        </h1>
        
        <br />
        <img
            src={data4 && data4.imageURL}
            alt=""
            className="w-[90%] p-3"
          />

        <p className='pt-3 text-[16px] font-[Poppins] text-[#000000ba] whitespace-pre-line px-6'>
        {data4 && data4.description ? data4.description : <SpinnerX />}
        </p>
        
        <div className='flex px-4'>
        <div className={`${styles.button} !bg-[#000] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Get a quote
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data4 && data4._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
              <br />
        <br />
        
        <br />
        </div>        
        
        
        </div>

      </div>
  )
}

export default Hero3
