import React, {useEffect, useState} from 'react';
import styles from "../../../styles/styles";
import { Container, Row, Col } from "react-bootstrap";
import people1 from "../../../Assests/images/complete2.png";
import 'animate.css';
import './horizon.css';
import TrackVisibility from 'react-on-screen';
import { server } from "../../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerX from "../../../components/Spinner/SpinnerX";



function HorizontalScroll() {

  const { seller } = useSelector((state) => state.seller);
  const [dataTU, setDataTU] = useState([]);

    useEffect(() => {
      axios.get(`${server}/pcard/get-all-pcards`, {withCredentials: true}).then((res) => {
        setDataTU(res.data.pcards);
    })
   }, []); 

   const datafetch5 = dataTU && dataTU.filter((i) => i.cardNumber === 5);

   const data5 = datafetch5[0];

   console.log(data5);
 
  return (
    <div className='w-full bg-[#012]'>
    <div className={`relative min-h-[60vh] 800px:min-h-[70vh] w-full bg-no-repeat ${styles.noramlFlex}`}>
        <div className={`${styles.section} w-[90%] 800px:w-[90%] gap-5`}>
        <section className="hcontact" id="contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6} className="cont-left">
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={data5 && data5.imageURL} alt="Freelancers"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6} className="cont-right">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeInRight" : ""}>
                <h1 className='text-[25px] font-[600] px-4 pb-5 pt-9 text-[#fff]'>{data5 && data5.name ? data5.name : <SpinnerX />}:</h1>
                <p className='text-[16px] text-[#fff] px-3 font-[300] whitespace-pre-line px-6'>
                {data5 && data5.description ? data5.description : <SpinnerX />}
                </p>
        
        <div className='flex px-4 mt-5'>
        <div className={`${styles.button} !bg-[#c89b2a] ml-4 !rounded-[4px]`}>
               <Link to="mailto:info@ohkoil.com" target="_blank">
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4">
                  Inquire now
                  </h1>
                </Link> 
              </div>

              {seller && <div className={`${styles.button} !ml-4 !bg-[blue]`}>
                <Link to={`/edit-pcard/${data5 && data5._id}`}>
                  <h1 className="text-[#fff] flex items-center py-[10px] px-[10px] text-xl/2">
                  Edit Card
                  </h1>
                </Link> 
                </div>} 
              </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
        </div>
    </div>
    
    </div>
  );
}

export default HorizontalScroll;