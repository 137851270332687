import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ProductSIngleCategoryCard4 from "../components/Route/ProductSIngleCategoryCard/ProductSIngle4CategoryCard4";
import SingleProductPage4 from "../pages/SingleProductPage4";
import Hero3d from "../components/Route/Hero/Hero3d";


const Page4 = () => {

         
    return (
    <div>
        <Header activeHeading={2} />

        <Hero3d />
        <ProductSIngleCategoryCard4 />
        <SingleProductPage4 />
        
        <Footer />
    </div>
    );
  };

export default Page4;
