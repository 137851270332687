import React from "react";
import { Link } from "react-router-dom";



const ProductCard = ({ data }) => {
  
  return (
    <>
    <div className="w-full">
       <Link to={`/product/${data._id}`}>
       <p className="pb-1 text-[#000]">
            {data.name}
        </p>
        </Link>
       </div>
    </>
  );
};

export default ProductCard;

